import React from "react";

import PageLayout from "../components/page-layout";

import ukraineImage from "../images/ukraine.png";

const PrivacyPage = () => {
  return (
    <PageLayout
      title="Call A Colleague - Privacy"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={ukraineImage}
    >
      <section>
        <h1>Datenschutz­erklärung</h1>
        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>{" "}
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <h3>Datenerfassung auf dieser Website</h3>{" "}
        <h4>
          Wer ist verantwortlich für die Datenerfassung auf dieser Website?
        </h4>{" "}
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
          „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
          entnehmen.
        </p>{" "}
        <h4>Wie erfassen wir Ihre Daten?</h4>{" "}
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
        </p>{" "}
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
          des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
          sobald Sie diese Website betreten.
        </p>{" "}
        <h4>Wofür nutzen wir Ihre Daten?</h4>{" "}
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </p>{" "}
        <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>{" "}
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
          dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
          Datenverarbeitung erteilt haben, können Sie diese Einwilligung
          jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
          unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        </p>{" "}
        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
          jederzeit an uns wenden.
        </p>
        <h3>Analyse-Tools und Tools von Dritt­anbietern</h3>{" "}
        <p>
          Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit sogenannten
          Analyseprogrammen.
        </p>{" "}
        <p>
          Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
          der folgenden Datenschutzerklärung.
        </p>
        <h2>2. Hosting</h2>
        <h3>DomainFactory</h3>{" "}
        <p>
          Wir hosten unsere Website bei DomainFactory. Anbieter ist die
          DomainFactory GmbH, Oskar-Messter-Str. 33, 85737 Ismaning (nachfolgend
          DomainFactory) Wenn Sie unsere Website besuchen, erfasst DomainFactory
          verschiedene Logfiles inklusive Ihrer IP-Adressen.
        </p>{" "}
        <p>
          Details entnehmen Sie der Datenschutzerklärung von DomainFactory:{" "}
          <a
            href="https://www.df.eu/de/datenschutz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.df.eu/de/datenschutz/
          </a>
          .
        </p>{" "}
        <p>
          Die Verwendung von DomainFactory erfolgt auf Grundlage von Art. 6 Abs.
          1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
          möglichst zuverlässigen Darstellung unserer Website. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
          Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder
          den Zugriff auf Informationen im Endgerät des Nutzers (z. B. für
          Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
          ist jederzeit widerrufbar.
        </p>
        <h4>Auftragsverarbeitung</h4>{" "}
        <p>
          Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
          genannten Anbieter geschlossen. Hierbei handelt es sich um einen
          datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
          dieser die personenbezogenen Daten unserer Websitebesucher nur nach
          unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
        </p>
        <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
        <h3>Datenschutz</h3>{" "}
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>{" "}
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
        </p>{" "}
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
          bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
          nicht möglich.
        </p>
        <h3>Hinweis zur verantwortlichen Stelle</h3>{" "}
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </p>{" "}
        <p>
          Einzelunternehmer Herr Elshad Shirinov
          <br />
          Gotzkowskystr. 23A, 10555 Berlin
        </p>
        <p>
          Telefon: +49 151 65165087
          <br />
          E-Mail: shirinov.elshad@gmail.com
        </p>
        <p>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <h3>Speicherdauer</h3>{" "}
        <p>
          Soweit innerhalb dieser Datenschutzerklärung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
          ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
          zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
          wir keine anderen rechtlich zulässigen Gründe für die Speicherung
          Ihrer personenbezogenen Daten haben (z. B. steuer- oder
          handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
          erfolgt die Löschung nach Fortfall dieser Gründe.
        </p>
        <h3>
          Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf
          dieser Website
        </h3>{" "}
        <p>
          Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
          wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit.
          a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
          Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle
          einer ausdrücklichen Einwilligung in die Übertragung personenbezogener
          Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf
          Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die
          Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr
          Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt
          die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG.
          Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
          Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs.
          1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
          zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf
          Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann
          ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs.
          1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
          Rechtsgrundlagen wird in den folgenden Absätzen dieser
          Datenschutzerklärung informiert.
        </p>
        <h3>
          Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
        </h3>{" "}
        <p>
          Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA
          oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn
          diese Tools aktiv sind, können Ihre personenbezogene Daten in diese
          Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf
          hin, dass in diesen Ländern kein mit der EU vergleichbares
          Datenschutzniveau garantiert werden kann. Beispielsweise sind
          US-Unternehmen dazu verpflichtet, personenbezogene Daten an
          Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
          hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
          ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf
          US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten,
          auswerten und dauerhaft speichern. Wir haben auf diese
          Verarbeitungstätigkeiten keinen Einfluss.
        </p>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{" "}
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
          erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <h3>
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen Direktwerbung (Art. 21 DSGVO)
        </h3>{" "}
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH
          AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
          PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
          AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
          RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
          DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
          IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
          SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
          VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
          ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG
          ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS.
          1 DSGVO).
        </p>{" "}
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
          DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
          SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
          MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
          ABS. 2 DSGVO).
        </p>
        <h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3>{" "}
        <p>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
          besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
          gerichtlicher Rechtsbehelfe.
        </p>
        <h3>Recht auf Daten­übertrag­barkeit</h3>{" "}
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
        <h3>SSL- bzw. TLS-Verschlüsselung</h3>{" "}
        <p>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </p>{" "}
        <p>
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
          Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
          werden.
        </p>
        <h3>Auskunft, Löschung und Berichtigung</h3>{" "}
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten können Sie sich jederzeit an uns wenden.
        </p>
        <h3>Recht auf Einschränkung der Verarbeitung</h3>{" "}
        <p>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
          folgenden Fällen:
        </p>{" "}
        <ul>
          {" "}
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit,
            um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
            Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
            Daten zu verlangen.
          </li>{" "}
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
            geschah/geschieht, können Sie statt der Löschung die Einschränkung
            der Datenverarbeitung verlangen.
          </li>{" "}
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
            jedoch zur Ausübung, Verteidigung oder Geltendmachung von
            Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
            die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen.
          </li>{" "}
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
            haben, muss eine Abwägung zwischen Ihren und unseren Interessen
            vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung
            Ihrer personenbezogenen Daten zu verlangen.
          </li>{" "}
        </ul>{" "}
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
        </p>
        <h3>Widerspruch gegen Werbe-E-Mails</h3>{" "}
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
          Werbung und Informationsmaterialien wird hiermit widersprochen. Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
          Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
          Spam-E-Mails, vor.
        </p>
        <h2>4. Datenerfassung auf dieser Website</h2>
        <h3>Cookies</h3>{" "}
        <p>
          Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
          kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
          Sie werden entweder vorübergehend für die Dauer einer Sitzung
          (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
          Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
          automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
          gespeichert, bis Sie diese selbst löschen oder eine automatische
          Löschung durch Ihren Webbrowser erfolgt.
        </p>{" "}
        <p>
          Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
          gespeichert werden, wenn Sie unsere Seite betreten
          (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
          bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
          Abwicklung von Zahlungsdienstleistungen).
        </p>{" "}
        <p>
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
          technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
          funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von
          Videos). Andere Cookies dienen dazu, das Nutzerverhalten
          auszuwerten oder Werbung anzuzeigen.
        </p>{" "}
        <p>
          Cookies, die zur Durchführung des elektronischen
          Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen
          erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur
          Optimierung der Website (z. B. Cookies zur Messung des Webpublikums)
          erforderlich sind (notwendige Cookies), werden auf Grundlage von Art.
          6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage
          angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an
          der Speicherung von notwendigen Cookies zur technisch fehlerfreien und
          optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung
          zur Speicherung von Cookies und vergleichbaren
          Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a
          DSGVO und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit
          widerrufbar.
        </p>{" "}
        <p>
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
          Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
          Annahme von Cookies für bestimmte Fälle oder generell ausschließen
          sowie das automatische Löschen der Cookies beim Schließen des Browsers
          aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
          dieser Website eingeschränkt sein.
        </p>{" "}
        <p>
          Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
          werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung
          gesondert informieren und ggf. eine Einwilligung abfragen.
        </p>
        <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{" "}
        <p>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>{" "}
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der effektiven Bearbeitung der an
          uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde; die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
          bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
          Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche
          Aufbewahrungsfristen – bleiben unberührt.
        </p>
        <h2>5. Analyse-Tools und Werbung</h2>
        <h3>Google Tag Manager</h3>{" "}
        <p>
          Wir setzen den Google Tag Manager ein. Anbieter ist die Google Ireland
          Limited, Gordon House, Barrow Street, Dublin 4, Irland.
        </p>{" "}
        <p>
          Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir Tracking-
          oder Statistik-Tools und andere Technologien auf unserer Website
          einbinden können. Der Google Tag Manager selbst erstellt keine
          Nutzerprofile, speichert keine Cookies und nimmt keine eigenständigen
          Analysen vor. Er dient lediglich der Verwaltung und Ausspielung der
          über ihn eingebundenen Tools. Der Google Tag Manager erfasst jedoch
          Ihre IP-Adresse, die auch an das Mutterunternehmen von Google in die
          Vereinigten Staaten übertragen werden kann.
        </p>{" "}
        <p>
          Der Einsatz des Google Tag Managers erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an einer schnellen und unkomplizierten Einbindung und
          Verwaltung verschiedener Tools auf seiner Website. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
          Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder
          den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
          Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
          ist jederzeit widerrufbar.
        </p>
        <h3>Hotjar</h3>{" "}
        <p>
          Diese Website nutzt Hotjar. Anbieter ist die Hotjar Ltd., Level 2, St
          Julians Business Centre, 3, Elia Zammit Street, St Julians STJ 1000,
          Malta, Europe (Website:{" "}
          <a href="" target="_blank" rel="noopener noreferrer">
            https://www.hotjar.com
          </a>
          ).
        </p>{" "}
        <p>
          Hotjar ist ein Werkzeug zur Analyse Ihres Nutzerverhaltens auf dieser
          Website. Mit Hotjar können wir u. a. Ihre Maus- und Scrollbewegungen
          und Klicks aufzeichnen. Hotjar kann dabei auch feststellen, wie lange
          Sie mit dem Mauszeiger auf einer bestimmten Stelle verblieben sind.
          Aus diesen Informationen erstellt Hotjar sogenannte Heatmaps, mit
          denen sich feststellen lässt, welche Websitebereiche vom
          Websitebesucher bevorzugt angeschaut werden.
        </p>{" "}
        <p>
          Des Weiteren können wir feststellen, wie lange Sie auf einer Seite
          verblieben sind und wann Sie sie verlassen haben. Wir können auch
          feststellen, an welcher Stelle Sie Ihre Eingaben in ein
          Kontaktformular abgebrochen haben (sog. Conversion-Funnels).
        </p>{" "}
        <p>
          Darüber hinaus können mit Hotjar direkte Feedbacks von
          Websitebesuchern eingeholt werden. Diese Funktion dient der
          Verbesserung der Webangebote des Websitebetreibers.
        </p>{" "}
        <p>
          Hotjar verwendet Technologien, die die Wiedererkennung des Nutzers zum
          Zwecke der Analyse des Nutzerverhaltens ermöglichen (z. B. Cookies
          oder Einsatz von Device-Fingerprinting).
        </p>{" "}
        <p>
          Die Nutzung dieses Analysetools erfolgt auf Grundlage von Art. 6 Abs.
          1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch
          seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung
          abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage
          von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die
          Einwilligung die Speicherung von Cookies oder den Zugriff auf
          Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
          Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <h4>Deaktivieren von Hotjar</h4>{" "}
        <p>
          Wenn Sie die Datenerfassung durch Hotjar deaktivieren möchten, klicken
          Sie auf folgenden Link und folgen Sie den dortigen Anweisungen:{" "}
          <a
            href="https://www.hotjar.com/policies/do-not-track/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.hotjar.com/policies/do-not-track/
          </a>
        </p>{" "}
        <p>
          Bitte beachten Sie, dass die Deaktivierung von Hotjar für jeden
          Browser bzw. für jedes Endgerät separat erfolgen muss.
        </p>{" "}
        <p>
          Nähere Informationen über Hotjar und zu den erfassten Daten entnehmen
          Sie der Datenschutzerklärung von Hotjar unter dem folgenden Link:{" "}
          <a
            href="https://www.hotjar.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.hotjar.com/privacy
          </a>
        </p>
        <h4>Auftragsverarbeitung</h4>{" "}
        <p>
          Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
          genannten Anbieter geschlossen. Hierbei handelt es sich um einen
          datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
          dieser die personenbezogenen Daten unserer Websitebesucher nur nach
          unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
        </p>
        <h3>Facebook Pixel</h3>{" "}
        <p>
          Diese Website nutzt zur Konversionsmessung der Besucheraktions-Pixel
          von Facebook. Anbieter dieses Dienstes ist die Meta Platforms Ireland
          Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten
          werden nach Aussage von Facebook jedoch auch in die USA und in andere
          Drittländer übertragen.
        </p>{" "}
        <p>
          So kann das Verhalten der Seitenbesucher nachverfolgt werden, nachdem
          diese durch Klick auf eine Facebook-Werbeanzeige auf die Website des
          Anbieters weitergeleitet wurden. Dadurch können die Wirksamkeit der
          Facebook-Werbeanzeigen für statistische und Marktforschungszwecke
          ausgewertet werden und zukünftige Werbemaßnahmen optimiert werden.
        </p>{" "}
        <p>
          Die erhobenen Daten sind für uns als Betreiber dieser Website anonym,
          wir können keine Rückschlüsse auf die Identität der Nutzer ziehen. Die
          Daten werden aber von Facebook gespeichert und verarbeitet, sodass
          eine Verbindung zum jeweiligen Nutzerprofil möglich ist und Facebook
          die Daten für eigene Werbezwecke, entsprechend der{" "}
          <a
            href="https://de-de.facebook.com/about/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook-Datenverwendungsrichtlinie
          </a>{" "}
          verwenden kann. Dadurch kann Facebook das Schalten von Werbeanzeigen
          auf Seiten von Facebook sowie außerhalb von Facebook ermöglichen.
          Diese Verwendung der Daten kann von uns als Seitenbetreiber nicht
          beeinflusst werden.
        </p>{" "}
        <p>
          Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
          nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die
          Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
          der EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/legal/EU_data_transfer_addendum
          </a>{" "}
          und{" "}
          <a
            href="https://de-de.facebook.com/help/566994660333381"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/help/566994660333381
          </a>
          .
        </p>{" "}
        <p>
          Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten
          auf unserer Website erfasst und an Facebook weitergeleitet werden,
          sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square,
          Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese
          Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame
          Verantwortlichkeit beschränkt sich dabei ausschließlich auf die
          Erfassung der Daten und deren Weitergabe an Facebook. Die nach der
          Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht Teil
          der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden
          Verpflichtungen wurden in einer Vereinbarung über gemeinsame
          Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie
          unter:{" "}
          <a
            href="https://www.facebook.com/legal/controller_addendum"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/legal/controller_addendum
          </a>
          . Laut dieser Vereinbarung sind wir für die Erteilung der
          Datenschutzinformationen beim Einsatz des Facebook-Tools und für die
          datenschutzrechtlich sichere Implementierung des Tools auf unserer
          Website verantwortlich. Für die Datensicherheit der Facebook-Produkte
          ist Facebook verantwortlich. Betroffenenrechte (z. B.
          Auskunftsersuchen) hinsichtlich der bei Facebook verarbeiteten Daten
          können Sie direkt bei Facebook geltend machen. Wenn Sie die
          Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese
          an Facebook weiterzuleiten.
        </p>{" "}
        <p>
          In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise
          zum Schutz Ihrer Privatsphäre:{" "}
          <a
            href="https://de-de.facebook.com/about/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/about/privacy/
          </a>
          .
        </p>{" "}
        <p>
          Sie können außerdem die Remarketing-Funktion „Custom Audiences“ im
          Bereich Einstellungen für Werbeanzeigen unter{" "}
          <a
            href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
          </a>{" "}
          deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.
        </p>{" "}
        <p>
          Wenn Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte
          Werbung von Facebook auf der Website der European Interactive Digital
          Advertising Alliance deaktivieren:{" "}
          <a
            href="http://www.youronlinechoices.com/de/praferenzmanagement/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.youronlinechoices.com/de/praferenzmanagement/
          </a>
          .
        </p>
        <h2>6. Newsletter</h2>
        <h3>Newsletter­daten</h3>{" "}
        <p>
          Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
          benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen,
          welche uns die Überprüfung gestatten, dass Sie der Inhaber der
          angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters
          einverstanden sind. Weitere Daten werden nicht bzw. nur auf
          freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich
          für den Versand der angeforderten Informationen und geben diese nicht
          an Dritte weiter.
        </p>{" "}
        <p>
          Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
          Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6
          Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der
          Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
          Newsletters können Sie jederzeit widerrufen, etwa über den
          „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits
          erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        </p>{" "}
        <p>
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
          Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei
          uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
          Abbestellung des Newsletters oder nach Zweckfortfall aus der
          Newsletterverteilerliste gelöscht. Wir behalten uns vor,
          E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen
          im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
          DSGVO zu löschen oder zu sperren.
        </p>{" "}
        <p>
          Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben
          hiervon unberührt.
        </p>{" "}
        <p>
          Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
          E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
          einer Blacklist gespeichert, sofern dies zur Verhinderung künftiger
          Mailings erforderlich ist. Die Daten aus der Blacklist werden nur für
          diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt.
          Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der
          Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern
          (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
          Speicherung in der Blacklist ist zeitlich nicht befristet.{" "}
          <strong>
            Sie können der Speicherung widersprechen, sofern Ihre Interessen
            unser berechtigtes Interesse überwiegen.
          </strong>
        </p>
        <h2>7. Plugins und Tools</h2>
        <h3>YouTube mit erweitertem Datenschutz</h3>{" "}
        <p>
          Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist
          die Google Ireland Limited („Google“), Gordon House, Barrow Street,
          Dublin 4, Irland.
        </p>{" "}
        <p>
          Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
          bewirkt laut YouTube, dass YouTube keine Informationen über die
          Besucher auf dieser Website speichert, bevor diese sich das Video
          ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den
          erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen.
          So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen –
          eine Verbindung zum Google DoubleClick-Netzwerk her.
        </p>{" "}
        <p>
          Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
          Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
          YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
          Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
          YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
          zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
          YouTube-Account ausloggen.
        </p>{" "}
        <p>
          Des Weiteren kann YouTube nach Starten eines Videos verschiedene
          Cookies auf Ihrem Endgerät speichern oder vergleichbare
          Wiedererkennungstechnologien (z. B. Device-Fingerprinting) einsetzen.
          Auf diese Weise kann YouTube Informationen über Besucher dieser
          Website erhalten. Diese Informationen werden u. a. verwendet, um
          Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern
          und Betrugsversuchen vorzubeugen.
        </p>{" "}
        <p>
          Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
          Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen
          Einfluss haben.
        </p>{" "}
        <p>
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
          Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder
          den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
          Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
          ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Weitere Informationen über Datenschutz bei YouTube finden Sie in deren
          Datenschutzerklärung unter:{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h2>8. Audio- und Videokonferenzen</h2>
        <h4>Datenverarbeitung</h4>{" "}
        <p>
          Für die Kommunikation mit unseren Kunden setzen wir unter anderen
          Online-Konferenz-Tools ein. Die im Einzelnen von uns genutzten Tools
          sind unten aufgelistet. Wenn Sie mit uns per Video- oder
          Audiokonferenz via Internet kommunizieren, werden Ihre
          personenbezogenen Daten von uns und dem Anbieter des jeweiligen
          Konferenz-Tools erfasst und verarbeitet.
        </p>{" "}
        <p>
          Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung der
          Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre
          Telefonnummer). Ferner verarbeiten die Konferenz-Tools die Dauer der
          Konferenz, Beginn und Ende (Zeit) der Teilnahme an der Konferenz,
          Anzahl der Teilnehmer und sonstige „Kontextinformationen“ im
          Zusammenhang mit dem Kommunikationsvorgang (Metadaten).
        </p>{" "}
        <p>
          Des Weiteren verarbeitet der Anbieter des Tools alle technischen
          Daten, die zur Abwicklung der Online-Kommunikation erforderlich sind.
          Dies umfasst insbesondere IP-Adressen, MAC-Adressen, Geräte-IDs,
          Gerätetyp, Betriebssystemtyp und -version, Client-Version, Kameratyp,
          Mikrofon oder Lautsprecher sowie die Art der Verbindung.
        </p>{" "}
        <p>
          Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder in
          sonstiger Weise bereitgestellt werden, werden diese ebenfalls auf den
          Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten zählen
          insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten,
          Voicemails hochgeladene Fotos und Videos, Dateien, Whiteboards und
          andere Informationen, die während der Nutzung des Dienstes geteilt
          werden.
        </p>{" "}
        <p>
          Bitte beachten Sie, dass wir nicht vollumfänglich Einfluss auf die
          Datenverarbeitungsvorgänge der verwendeten Tools haben. Unsere
          Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik des
          jeweiligen Anbieters. Weitere Hinweise zur Datenverarbeitung durch die
          Konferenztools entnehmen Sie den Datenschutzerklärungen der jeweils
          eingesetzten Tools, die wir unter diesem Text aufgeführt haben.
        </p>{" "}
        <h4>Zweck und Rechtsgrundlagen</h4>{" "}
        <p>
          Die Konferenz-Tools werden genutzt, um mit angehenden oder bestehenden
          Vertragspartnern zu kommunizieren oder bestimmte Leistungen gegenüber
          unseren Kunden anzubieten (Art. 6 Abs. 1 lit. b DSGVO). Des Weiteren
          dient der Einsatz der Tools der allgemeinen Vereinfachung und
          Beschleunigung der Kommunikation mit uns bzw. unserem Unternehmen
          (berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO).
          Soweit eine Einwilligung abgefragt wurde, erfolgt der Einsatz der
          betreffenden Tools auf Grundlage dieser Einwilligung; die Einwilligung
          ist jederzeit mit Wirkung für die Zukunft widerrufbar.
        </p>{" "}
        <h4>Speicherdauer</h4>{" "}
        <p>
          Die unmittelbar von uns über die Video- und Konferenz-Tools erfassten
          Daten werden von unseren Systemen gelöscht, sobald Sie uns zur
          Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder
          der Zweck für die Datenspeicherung entfällt. Gespeicherte Cookies
          verbleiben auf Ihrem Endgerät, bis Sie sie löschen. Zwingende
          gesetzliche Aufbewahrungsfristen bleiben unberührt.
        </p>{" "}
        <p>
          Auf die Speicherdauer Ihrer Daten, die von den Betreibern der
          Konferenz-Tools zu eigenen Zwecken gespeichert werden, haben wir
          keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte
          direkt bei den Betreibern der Konferenz-Tools.
        </p>{" "}
        <h4>Eingesetzte Konferenz-Tools</h4>{" "}
        <p>Wir setzen folgende Konferenz-Tools ein:</p>
        <h3>Jitsi Meet</h3>
        <p>
          Wir nutzen Jitsi Meet. Wenn Sie über Jitsi Meet mit uns kommunizieren,
          werden alle mit diesem Kommunikationsvorgang verbundenen Daten
          ausschließlich auf Servern innerhalb der Europäischen Union bzw. einem
          datenschutzrechtlich sicheren Drittstaat verarbeitet.
        </p>
        <h2>9. Eigene Dienste</h2>
        <h3>Umgang mit Bewerberdaten</h3>{" "}
        <p>
          Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B. per
          E-Mail, postalisch oder via Online-Bewerberformular). Im Folgenden
          informieren wir Sie über Umfang, Zweck und Verwendung Ihrer im Rahmen
          des Bewerbungsprozesses erhobenen personenbezogenen Daten. Wir
          versichern, dass die Erhebung, Verarbeitung und Nutzung Ihrer Daten in
          Übereinstimmung mit geltendem Datenschutzrecht und allen weiteren
          gesetzlichen Bestimmungen erfolgt und Ihre Daten streng vertraulich
          behandelt werden.
        </p>{" "}
        <h4>Umfang und Zweck der Datenerhebung </h4>{" "}
        <p>
          Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre
          damit verbundenen personenbezogenen Daten (z. B. Kontakt- und
          Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
          Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die
          Begründung eines Beschäftigungsverhältnisses erforderlich ist.
          Rechtsgrundlage hierfür ist § 26 BDSG nach deutschem Recht (Anbahnung
          eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b DSGVO
          (allgemeine Vertragsanbahnung) und – sofern Sie eine Einwilligung
          erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung ist
          jederzeit widerrufbar. Ihre personenbezogenen Daten werden innerhalb
          unseres Unternehmens ausschließlich an Personen weitergegeben, die an
          der Bearbeitung Ihrer Bewerbung beteiligt sind.
        </p>{" "}
        <p>
          Sofern die Bewerbung erfolgreich ist, werden die von Ihnen
          eingereichten Daten auf Grundlage von § 26 BDSG und Art. 6 Abs. 1 lit.
          b DSGVO zum Zwecke der Durchführung des Beschäftigungsverhältnisses in
          unseren Datenverarbeitungssystemen gespeichert.
        </p>{" "}
        <h4>Aufbewahrungsdauer der Daten</h4>{" "}
        <p>
          Sofern wir Ihnen kein Stellenangebot machen können, Sie ein
          Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten wir
          uns das Recht vor, die von Ihnen übermittelten Daten auf Grundlage
          unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) bis zu 6
          Monate ab der Beendigung des Bewerbungsverfahrens (Ablehnung oder
          Zurückziehung der Bewerbung) bei uns aufzubewahren. Anschließend
          werden die Daten gelöscht und die physischen Bewerbungsunterlagen
          vernichtet. Die Aufbewahrung dient insbesondere Nachweiszwecken im
          Falle eines Rechtsstreits. Sofern ersichtlich ist, dass die Daten nach
          Ablauf der 6-Monatsfrist erforderlich sein werden (z. B. aufgrund
          eines drohenden oder anhängigen Rechtsstreits), findet eine Löschung
          erst statt, wenn der Zweck für die weitergehende Aufbewahrung
          entfällt.
        </p>{" "}
        <p>
          Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine
          entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt haben
          oder wenn gesetzliche Aufbewahrungspflichten der Löschung
          entgegenstehen.
        </p>
      </section>
    </PageLayout>
  );
};

export default PrivacyPage;
